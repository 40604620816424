export const SMALL_BUSINESS_FORM = "Small Business Form";
export const SBF = "SBF";

export const LABELS = {
  CONSIDERED_SMALL_BUSINESS_SUPPLIERS: "Did you consider Small Business Suppliers?",
  REASONS_SMALL_BUSINESS_SUPPLIERS: "Reason for why you didn't consider Small Business Suppliers?",
  CREATE_SMALL_BUSINESS_FORM: "Create Small Business Form",
  SEARCH_SMALL_BUSINESS_FORM: "Search Small Business Form",
  SBF_NUMBER_0_9: "SBF Number 0-9",
  SBF_NUMBER_9_0: "SBF Number 9-0",
  SBA_SIZE_STANDARDS: "SBA Size Standards",
}

export const MESSAGES = {
  RETURN_TO_STRATEGY: `You may return to the strategy which now includes this ${SMALL_BUSINESS_FORM}.`,
  SUCCESSFULLY_CREATED: `${SBF} successfully created.`,
  SUCCESSFULLY_UPDATED: `${SBF} successfully updated.`,
  SBF_SAVE_ERROR: "Couldn't save the SB Form. Please try again.",
  ERROR_RECERTIFY_SBF: "Couldn't recertify the SB Form. Please try again.",
}

//Name not available
export const NOT_AVAILABLE = "n/a";
export const FORM_TYPE_IN_STRATEGY = "InStrategy";
export const FORM_TYPE_NOT_IN_STRATEGY = "NotInStrategy";
export const FORM_TYPE_ID_IN_STRATEGY = "Strategy";
export const FORM_TYPE_ID_NOT_IN_STRATEGY = "Non-Strategy";
export const STRATEGY_TYPE_DIRECT = "D";
export const STRATEGY_TYPE_INDIRECT = "N";
export const INDIRECT_STRATEGY_MANAGEMENT = "sm";
export const DIRECT_STRATEGY_MANAGEMENT = "dmsm";
export const GLOBAL_STRATEGIC_SUPPLIER = "gss";
export const SCOPE_LEVEL_RPCORUNIT = "RPC or Unit";
export const SCOPE_LEVEL_UNITORUNITGROUP = "Unit or Unit Group";
export const SCOPE_LEVEL_GLOBAL = "Global";

export const OTHER_REASON = "11";

export const SMALL_BUSINESS_ADMINISTRATION = "SBA (Small Business Administration)";

//Vetted Supplier
export const NON_CURRENT_OR_PROSPECTIVE_SUPPLIER =
  "Non Current or Prospective Supplier";
export const CURRENT_SUPPLIER = "Current Supplier";

//Vetted Supplier
export const PROSPECTIVE = "Prospective";
export const CURRENT = "Current";

//Constants for Pagination
export const MAX_RESULT_PER_PAGE = 20;
export const MAX_VISIBLE_PAGES = 5;

export const YES = "y";
export const NO = "n";
